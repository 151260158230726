<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import { ref } from '@vue/composition-api'
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import {formatDatepicker, formatNumber} from '@/utils/formatter'

export default {
  page: {
    title: "Revenue Report",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { 
    DatePicker,
    Layout,
    PageHeader,
  },
  setup(){

    const { $get } = useHttp()

   const LOV = ref({
      dataExcel:[]
    })

   const form = ref({
      ordDateFrom:'',
      ordDateThru:'',
      order:[]
    })

    const paramdate = ref(['',''])


     const getOrderlist = async() =>{  
        let scheduleParams = {
          ordDateFrom : paramdate.value[0],
          ordDateThru : paramdate.value[1],
        }
        const {data} = await $get({ url: 'master/report_revenue', params: scheduleParams})
        form.value.order = data
        LOV.value.dataExcel = data
    }

    const searchFlight = () =>{ 
      getOrderlist()
    }

    return {
      LOV,
      form,
      getOrderlist,
      searchFlight,
      formatNumber,
      formatDatepicker,
      paramdate
    }
  },

 data() {
    return {
      title: "Revenue Report",
      items: [],
      select_label: "Select"
    };
  },
  methods: {
    customLabel ({ prt_initial, prt_locationname }) {
      return `${prt_initial} – ${prt_locationname}`
    },
    printOrder() {
      window.print()
    },
    /*exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.LOV.dataExcel

        // add title
        // const title = [this.$store.state.user.currentCompany.name, this.$t('apps.reportLedger.singular.ledgerReport'), this.periodReportText]
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            ['Ledger Report', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Ledger Report ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    }*/
  }
};
</script>

<style lang="scss">
  .showonlyprint {
    display: none;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
      }
      .showonlyprint {
        display: block;
      }
      .printwrap, .printwrap *  {
        visibility: visible;
      }
      .printwrap {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        padding: 27px 32px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
            <div class="col-lg-12">
             <form class="form-horizontal" role="form">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Order Date"
                    label-for="order_date"
                  >
                    <date-picker  v-model="paramdate" format='DD-MM-YYYY' valueType="format"  range append-to-body lang="en"></date-picker>
                  </b-form-group>
                  </form>

                <div class="col-12 text-center">
                  <div class="button-items">
                    <b-button variant="primary" @click.prevent="searchFlight">
                      Search
                    </b-button>
                    <!-- <b-button variant="primary" @click.prevent="exportToExcel">
                    <i class="mdi mdi-file-download-outline align-middle"></i>
                      Export Excel
                    </b-button> -->
                  </div>
                </div>

              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-12 text-right">
                <b-button variant="primary" @click.prevent="printOrder">
                  <i class="ri-printer-line"></i>
                  Print
                </b-button>
              </div>
            </div>
            <div class="row printwrap mt-">
              <div class="col-12 text-center showonlyprint mb-2">
                Order Report
              </div>
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table font-size-13 table-bordered">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Order No.</th>
                        <th>Customer</th>
                        <th>Airlines</th>
                        <th>Kilo</th>
                        <th>Port Origin</th>
                        <th>Port Destination</th>
                        <th>Total Freight</th>
                        <th>Total Surcharge</th>
                        <th>Total Handling</th>
                        <th>Grand Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data,index) in form.order" :key="index">
                        <th scope="row">{{index+1}}</th>
                        <td>{{data.ord_no}}</td>
                        <td>{{data.cust_name}}</td>
                        <td>{{data.vnd_name}}</td>
                        <td class="text-right">{{formatNumber(data.ord_weight)}}</td>
                        <td>{{data.oriprt_initial}}</td>
                        <td>{{data.desprt_initial}}</td>
                        <td class="text-right">{{formatNumber(data.ord_freightcharge)}}</td>
                        <td class="text-right">{{formatNumber(data.ord_surcharge)}}</td>
                        <td class="text-right">{{formatNumber(data.ord_handlingcharge)}}</td>
                        <td class="text-right">{{formatNumber(data.ord_gtotalcharge)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>